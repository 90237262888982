@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"


















































































































































.event-page
  .text
    .eventtype
      margin-bottom: m(1)
      text-transform: uppercase
      +font('bold', 'small')
      .sold
        margin-left: m(1)
        padding: 0px 6px
        line-height: 1
        color: $white
        background-color: $warning-color
        border-radius: $radius
    h1
      margin-top: 0
      margin-bottom: 0
    h2
      margin-top: 0
    .datetime
      margin-top: m(1)
      .date
        +font('medium')
    .speaker
      margin-top: m(1)
    .label
       color: $grey-light
    .booking
      .sold
        +font('bold')
      .boxoffice,
      .link
        &:after
          content: " | "
          color: $text-color
        &:last-child
          &:after
            content: ""

+sm
  .event-page
    .text
      .label
        padding-bottom: 0
      .value
        padding-top: 0

